import { graphql } from "gatsby";
import React from "react";
import Results from "../components/results";
import Layout from "../modules/layout";
import SEO from "../modules/seo";

const PublicationOverview = ({
  data: {
    highlightedPublicationGeneral,
    publications,
    allContentfulFocusOfWork,
    allContentfulOrganization,
    allContentfulTopic,
    allContentfulRegion,
  },
  pageContext,
}) => {
  const highlightedPubReader = highlightedPublicationGeneral?.publication?.publicationDetail.reader
      .content[0].content[0].value
  const highlightedPublication = highlightedPublicationGeneral && {
    generalTitle: highlightedPublicationGeneral.publication.title,
    fixedReader: highlightedPubReader
      ? highlightedPubReader.length > 260
        ? highlightedPubReader.substring(0, 257) + "..."
        : highlightedPubReader
      : "",
    ...highlightedPublicationGeneral.publication.publicationDetail,
  };
  const { microcopy, node_locale } = pageContext.settings;

  const siteSettings = pageContext.settings;
  const topics = allContentfulTopic.edges.map(edge => edge.node);
  const focusesOfWork = allContentfulFocusOfWork.edges.map(edge => edge.node);
  const organizations = allContentfulOrganization.edges.map(edge => edge.node);
  const regions = allContentfulRegion.edges.map(edge => edge.node);

  const items = publications.edges.map(edge => {
    const reader =
      edge.node.publicationDetail.reader &&
      edge.node.publicationDetail.reader.content &&
      edge.node.publicationDetail.reader.content[0].content[0].value;
    let fixedReader;
    if (reader) {
      fixedReader =
        reader.length > 260 ? reader.substring(0, 257) + "..." : reader;
    }
    return {
      ...edge.node.publicationDetail,
      fixedReader,
      generalTitle: edge.node.title,
    };
  });

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${pageContext.name}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <div className="publicationOverview">
        <Results
          type="publikationen"
          highlightedPublication={highlightedPublication}
          topics={topics}
          focusesOfWork={focusesOfWork}
          organizations={organizations}
          regions={regions}
          items={items}
          microcopy={microcopy}
          locale={node_locale}
        />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($node_locale: String!) {
    publications: allContentfulPublicationGeneral(
      filter: { publicationDetail: { node_locale: { eq: $node_locale } } }
    ) {
      edges {
        node {
          title
          publicationDetail {
            id
            visibleTags
            node_locale
            title
            document {
              title
              file {
                url
              }
            }
            content {
              json
            }
            focusOfWork {
              contentful_id
              id
              name
            }
            organizations {
              id
              name
            }
            publicationDateTime
            topic {
              contentful_id
              id
              name
            }
            region {
              contentful_id
              id
              name
            }
            imagePortrait {
              imagePortrait {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                  aspectRatio
                }
              }
            }
            imageLandscape {
              imageLandscape {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                  aspectRatio
                }
              }
            }
            reader {
              content {
                content {
                  value
                }
              }
            }
            author {
              id
              name
            }
          }
        }
      }
    }
    highlightedPublicationGeneral: contentfulHighlightedPublication(
      node_locale: { eq: $node_locale }
    ) {
      publication {
        title
        publicationDetail {
          id
          visibleTags
          node_locale
          title
          document {
            title
            file {
              url
            }
          }
          content {
            json
          }
          focusOfWork {
            contentful_id
            id
            name
          }
          organizations {
            id
            name
          }
          publicationDateTime
          topic {
            contentful_id
            id
            name
          }
          region {
            contentful_id
            id
            name
          }
          imagePortrait {
            imagePortrait {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                aspectRatio
              }
            }
          }
          imageLandscape {
            imageLandscape {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                aspectRatio
              }
            }
          }
          reader {
            content {
              content {
                value
              }
            }
          }
          author {
            id
            name
          }
        }
      }
    }
    allContentfulFocusOfWork(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
    allContentfulOrganization(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
    allContentfulTopic(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
    allContentfulRegion(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          id
          contentful_id
          name
        }
      }
    }
  }
`;

export default PublicationOverview;
